<template>
  <div class="z-0">
    <!-- Header -->
    <header class="bg-white lg:overflow-y-visible border-b border-gray-200">
      <div class="mx-auto max-w-7xl py-3.5">
        <div class="relative flex items-center justify-between lg:gap-8 xl:grid xl:grid-cols-12">
          <div class="flex md:absolute md:inset-y-0 md:left-0 lg:static xl:col-span-2">
            <div class="flex flex-shrink-0 items-center">
              <NuxtLink to="/app/documents">
                <img class="h-8 w-auto" src="assets/img/logo_wide.png" alt="Paperarchive Logo" />
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </header>
    <slot />
  </div>
</template>

<script setup>
const authStore = useAuthStore();
const {user} = storeToRefs(authStore);
const router = useRoute();

const userNavigation = [
  {name: 'Billing', href: '/app/billing'},
  {name: 'Settings', href: '/app/settings'},
  {name: 'Changelog', href: '/app/changelog'},
  {name: 'Support', href: '/app/support'},
  {name: 'Sign out', href: '/app/logout'}
];

const showingDetails = ref(false);
const activeRoute = router.path;

function showDetails() {
  showingDetails.value = true;
}
</script>

<style scoped>
.credit-balance {
  cursor: pointer;
  /* ... existing styles ... */
}
</style>
